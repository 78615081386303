<template>
  <div v-if="step == 1">
    <div class="card shadow-sm">
      <form id="formItems" class="needs-validation" novalidate>
        <div class="card-header">
          <h3 class="card-title" style="font-size: 20px; font-weight: bold">
            เอกสารรับเข้า
          </h3>
        </div>
        <!-- form -->
        <div class="card-body">
          <div class="row mb-6">
            <!-- <div class="col-xs-12 col-md-12 block border"> -->
            <div class="wrapper-progressBar">
              <ul class="progressBar">
                <li class="active">สร้างเอกสาร</li>
                <li class="active">ผู้เกี่ยวข้อง 1 ลงนาม</li>
                <li class="">ผู้เกี่ยวข้อง 2 ลงนาม</li>
                <li class="">เสร็จสิ้น</li>
              </ul>
            </div>
          </div>
          <!-- </div> -->
          <!-- -->
          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductCode"
              class="required form-label col-sm-10"
              >จาก</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.from"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
                required
              />
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>
          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="required form-label col-sm-10"
              >เรียน</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.to"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
                required
              />
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>
          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="required form-label col-sm-10"
              >เรื่อง (ชื่อไฟล์ในระบบคู่กับเลขที่หนังสือ)</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.subject"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
                required
              />
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>

          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="required form-label col-sm-10"
              >วันที่ (ในหนังสือ)</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.date"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
                required
              />
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>

          <div class="row d-flex justify-content-center mb-4">
            <label
              for="secondaryProductName"
              class="required form-label col-sm-10"
              >วันที่ (รับหนังสือ)</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.since"
                type="text"
                class="form-control"
                name="secondaryProductName"
                id="secondaryProductName"
                required
              />
              <div class="invalid-feedback text-end">กรุณากรอก</div>
            </div>
          </div>
        </div>

        <hr class="pt-0 mt-0" style="color: LightGrey" />
        <form id="formItems" class="needs-validation" novalidate>
          <!-- form -->
          <div class="card-body row d-flex justify-content-center">
            <!-- -->

            <div class="col-sm-10 mb-4">
              <label for="validationTooltip01" class="form-label"
                >ประเภทไฟล์</label
              >
              <select
                :style="
                  isSubmit
                    ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                    : ''
                "
                class="form-select"
                v-model="form.status"
              >
                <option value="normal">file type1</option>
                <option value="holding">file type2</option>
                <option value="normal">file type3</option>
              </select>
            </div>

            <div class="col-sm-8">
              <label for="secondaryProductCode" class="form-label col-sm-10"
                >นำเอกสารเข้าระบบ</label
              >
              <div>
                <input
                  type="file"
                  class="form-control"
                  name="secondaryProductCode"
                  id="secondaryProductCode"
                />
                <div class="invalid-feedback text-end">กรุณากรอก</div>
              </div>
            </div>
            <div class="col-sm-2">
              <label
                for="secondaryProductCode"
                class="form-label col-sm-10"
              ></label>
              <button
                type="submit"
                class="btn btn-sm btn-light btn-primary ms-3 me-2 mt-2"
                style="font-size: 14px; font-weight: bold; place-self: center"
                @click="submit"
                :disabled="loadingUpdate"
              >
                <div
                  v-if="loadingUpdate"
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                ></div>
                บันทึกไฟล์
              </button>
            </div>

            <!-- <footer class="m-6">
            <div class="card-toolbar row justify-content-center">
              <div class="col-sm-10">
                <button
                  type="submit"
                  class="btn btn-sm btn-light btn-primary ms-3 me-2"
                  style="font-size: 14px; font-weight: bold"
                  @click="submit"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  บันทึกไฟล์
                </button>
              </div>
            </div>
          </footer> -->

            <div class="table-responsive col-sm-10 mt-6">
              <table
                class="
                  table table-hover table-rounded table-striped
                  border
                  gy-7
                  gs-7
                "
              >
                <thead>
                  <tr
                    class="
                      fw-bold
                      fs-5
                      text-gray-800
                      border-bottom-2 border-gray-200
                    "
                  >
                    <th></th>
                    <th>ลำดับที่</th>
                    <th>ชื่อไฟล์</th>
                    <th>ประเภทไฟล์</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-if="dataItemsShow.length < 1">
                    <td class="text-center" colspan="10">ไม่มีข้อมูล</td>
                  </tr>
                  <tr v-for="(item, index) in dataItemsShow" :key="index">
                    <td></td>
                    <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
                    <td>{{ item.filename }}</td>
                    <td>{{ item.filetype }}</td>
                    <td></td>

                    <!-- <td
                @click="item.status !== '1' ? handleApprove(item) : ''"
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td> -->
                    <td>
                      <div class="row">
                        <div class="col-sm-6 d-flex justify-content-md-end">
                          <button
                            type="button"
                            class="
                              btn
                              btn-link
                              btn-color-muted
                              btn-active-color-success
                              p-0
                            "
                            @click="goToEdit(item.id)"
                          >
                            <i class="bi bi-pencil-square"></i>
                          </button>
                        </div>
                        <div class="col-sm-6 d-flex">
                          <button
                            type="button"
                            class="
                              btn
                              btn-link
                              btn-color-muted
                              btn-active-color-danger
                              p-0
                            "
                            @click="goToDelete(item, index)"
                          >
                            <i class="bi bi-trash3-fill"></i>
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <hr class="pt-0 mt-0" style="color: LightGrey" />

          <div class="card-body row d-flex justify-content-center">
            <label for="secondaryProductCode" class="form-label col-sm-10"
              >สรุปรายละเอียด</label
            >
            <div class="col-sm-10">
              <!-- <input
                v-model="form.summarize"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              /> -->
              <editor
                initialValue="<p>Initial editor content</p>"
                :init="{
                  height: 300,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap',
                    'searchreplace visualblocks code fullscreen',
                    'print preview anchor insertdatetime media',
                    'paste code help wordcount table',
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic |         alignleft aligncenter alignright |         bullist numlist outdent indent | help',
                }"
              >
              </editor>
            </div>
            <!-- -->
            <div class="row d-flex justify-content-center mb-4 col-sm-10">
              <label for="validationTooltip01" class="form-label"
                >ส่งเอกสาร</label
              >
              <select
                :style="
                  isSubmit
                    ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                    : ''
                "
                class="form-select col-sm-10"
                v-model="form.status"
              >
                <option value="normal">หน่วยงาน1</option>
                <option value="holding">หน่วยงาน2</option>
                <option value="normal">หน่วยงาน3</option>
              </select>
            </div>
          </div>

          <footer class="m-6">
            <div class="card-toolbar row justify-content-center">
              <div class="col-sm-10">
                <button
                  type="submit"
                  class="btn btn-sm btn-light btn-primary ms-3 me-2"
                  style="font-size: 14px; font-weight: bold"
                  @click="submit"
                  :disabled="loadingUpdate"
                >
                  <div
                    v-if="loadingUpdate"
                    class="spinner-grow spinner-grow-sm text-light"
                    role="status"
                  ></div>
                  แก้ไข
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-light"
                  style="font-size: 14px; font-weight: bold"
                  @click="cancel"
                >
                  ยกเลิก
                </button>
              </div>
            </div>
          </footer>
        </form>

        <!-- <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="botton"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="step=2"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              ถัดไป
            </button>
            
          </div>
        </div>
      </footer> -->
      </form>
    </div>
  </div>

  <div v-if="step == 2">
    <div class="card shadow-sm">
      <form id="formItems" class="needs-validation" novalidate>
        <div class="card-header">
          <h3 class="card-title" style="font-size: 20px; font-weight: bold">
            เอกสารรับเข้า step2
          </h3>
        </div>
        <!-- form -->
        <div class="card-body">
          <!-- -->
          <div class="row d-flex justify-content-center mb-4">
            <label for="secondaryProductCode" class="form-label col-sm-10"
              >สรุปรายละเอียด</label
            >
            <div class="col-sm-10">
              <input
                v-model="form.summarize"
                type="text"
                class="form-control"
                name="secondaryProductCode"
                id="secondaryProductCode"
              />
            </div>
          </div>
        </div>

        <hr class="pt-0 mt-0" style="color: LightGrey" />

        <footer class="m-6">
          <div class="card-toolbar row justify-content-center">
            <div class="col-sm-10">
              <button
                type="botton"
                class="btn btn-sm btn-light btn-primary ms-3 me-2"
                style="font-size: 14px; font-weight: bold"
                @click="step = 3"
                :disabled="loading"
              >
                <div
                  v-if="loading"
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                ></div>
                ถัดไป
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  </div>

  <div v-if="step == 3">
    <div class="card shadow-sm">
      <form id="formItems" class="needs-validation" novalidate>
        <div class="card-header">
          <h3 class="card-title" style="font-size: 20px; font-weight: bold">
            เอกสารรับเข้า step3
          </h3>
        </div>
        <!-- form -->
        <div class="card-body row d-flex justify-content-center">
          <!-- -->
          <div class="row d-flex justify-content-center mb-4 col-sm-10">
            <label
              for="validationTooltip01"
              class="form-label justify-content-center"
              >ส่งเอกสาร</label
            >
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select col-sm-10"
              v-model="form.status"
            >
              <option value="normal">หน่วยงาน1</option>
              <option value="holding">หน่วยงาน2</option>
              <option value="normal">หน่วยงาน3</option>
            </select>
          </div>
        </div>

        <hr class="pt-0 mt-0" style="color: LightGrey" />

        <footer class="m-6">
          <div class="card-toolbar row justify-content-center">
            <div class="col-sm-10">
              <button
                type="botton"
                class="btn btn-sm btn-light btn-primary ms-3 me-2"
                style="font-size: 14px; font-weight: bold"
                @click="step = 4"
                :disabled="loading"
              >
                <div
                  v-if="loading"
                  class="spinner-grow spinner-grow-sm text-light"
                  role="status"
                ></div>
                save
              </button>
            </div>
          </div>
        </footer>
      </form>
    </div>
  </div>

  <div v-if="step == 4">step5</div>

  <div v-if="step == 6">step6</div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import Editor from "@tinymce/tinymce-vue";

export default {
  setup() {
    document.title = "bem-document-demo เพิ่มประเภทสินค้า";
  },
  components: {
    editor: Editor,
  },
  data: () => ({
    loading: false,
    isSubmit: false,
    step: 1,

    form: {
      sub_product_group_code: "",
      sub_product_group_name: "",
      selectMainProduct: "",
      status: "1",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    mainProduct: [],
    dataItemsShow: [],
    tableItems: [],
  }),

  created() {
    this.getMainProduct();
  },

  watch: {
    "form.productGroupId"(val) {
      console.log({ val });
    },
  },

  methods: {
    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await whApi.mainProduct.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        this.form.productGroupId = responseData.data[0].id;
      }
    },
    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.form = {
          ...this.form,
          mainProductGroupId: this.form.productGroupId,
          companyId: parseInt(localStorage.getItem("companyId")),
        };

        createResponse = await whApi.subProduct.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/WH2SUB",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${createResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.from
        ? (this.isValid = false)
        : !this.form.to
        ? (this.isValid = false)
        : !this.form.subject
        ? (this.isValid = false)
        : !this.form.date
        ? (this.isValid = false)
        : !this.form.since
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/saraban5",
        query: {},
      });
    },
  },
};
</script>
<style>
.wrapper-progressBar {
  width: 100%;
}

.progressBar {
}

.progressBar li {
  list-style-type: none;
  float: left;
  width: 25%;
  position: relative;
  text-align: center;
}
.progressBar li:before {
  content: " ";
  line-height: 30px;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  border-left: none;
  display: block;
  text-align: center;
  margin: 8.5px auto 0px;
  background-color: #eee;
}
.progressBar li:after {
  content: "";
  position: absolute;
  width: 97%;
  height: 5px;
  background-color: #eee;
  border: 1px solid #ddd;
  border-right: none;
  top: 15px;
  left: -50%;
  z-index: -1;
}

.progressBar li:first-child:after {
  content: none;
}

.progressBar li.active {
  color: dodgerblue;
}

.progressBar li.active:before {
  border-color: dodgerblue;
  background-color: dodgerblue;
}

.progressBar .active:after {
  background-color: dodgerblue;
}
</style>